import React, { useEffect, useState, useRef } from "react";
import TextareaAutosize from "react-textarea-autosize";
import axios from "axios";

import Modal from "../../modal/Modal";
import config from "../../../config";

import axiosCall from "../../../lib/axios";
import refreshToken from "../../../lib/refreshToken";
import encryption from "../../../lib/encryption";
import { formatBytes } from "../../../lib/common";

import Office from "../../../media/office.svg";
import Pdf from "../../../media/pdf.svg";

const KnowledgeCreator = ({
  bid,
  language,
  signout,
  onCancel,
  onSuccess,
  setKnowledge,
  cancelLabel,
}) => {
  ////////////// INITIALIZE //////////////////
  const docType = useRef();
  const [postType, setPostType] = useState("general");
  const [doc, setDoc] = useState(false);
  const [headline, setHeadline] = useState("");
  const [post, setPost] = useState("");

  const [aiAssist, setAiAssist] = useState(false);
  const [aiModal, setAiModal] = useState(false);
  const [aiRedo, setAiRedo] = useState(false);
  const abortAxios = useRef();

  const _imageFiles = useRef();
  const [imageFiles, setImageFiles] = useState([]);
  const [imageString, setImageString] = useState("");

  const _docFile = useRef();
  const docIcon = useRef();
  const [docFile, setDocFile] = useState([]);
  const [docString, setDocString] = useState("");

  const _filesSize = useRef();
  const [filesSize, setFilesSize] = useState(0);

  const [errHeadline, setErrHeadline] = useState(false);
  const [errPost, setErrPost] = useState(null);
  const [errImages, setErrImages] = useState(null);
  const [errDocument, setErrDocument] = useState(null);
  const [dlp, setDlp] = useState("");
  const [unknownErr, setUnknownErr] = useState(false);

  const [uploadModal, setUploadModal] = useState(false);
  const [processing, setProcessing] = useState(true);

  useEffect(() => {
    _imageFiles.current = [];
    _docFile.current = [];
    _filesSize.current = 0;
    docIcon.current = Office;
  }, []);

  ////////////// AI //////////////////

  const abort = () => {
    abortAxios.current = 0;
    setAiModal(false);
  };

  const preAssist = () => {
    if (post && post.trim() !== "") {
      setAiRedo(true);
    } else {
      assist();
    }
  };

  const assist = async () => {
    clearErrors();
    setAiRedo(false);

    // unabort axios call
    const timestamp = Date.now();
    abortAxios.current = timestamp;

    //check inputs
    let data = { bid };
    if (headline.trim() !== "") data.headline = headline.trim();

    setAiModal(true);

    // try and get article
    let result = await axiosCall("knowledge/article", data, false);

    // process result if call not aborted
    if (timestamp === abortAxios.current) {
      if (result.success) {
        setAiModal(false);
        try {
          if (result.data.title && !data.headline)
            setHeadline(result.data.title);
          setPost(result.data.body);
        } catch (err) {
          setErrPost(language.labels.ai.unknown);
        }
      } else if (result.refresh) {
        //token has been refreshed, try again
        assist();
      } else {
        //refresh token expired or unknown error
        signout();
      }
    }
  };

  ////////////// FORM //////////////////
  const submitKnowledge = () => {
    clearErrors();
    //check headline
    if (headline.trim() === "") {
      setErrHeadline(true);
      document.getElementById("title_field").focus();
      return;
    }
    //check post
    if (postType !== "question") {
      if (doc) {
        if (docFile.length === 0) return;
      } else {
        if (post.trim() === "") {
          setErrPost(language.labels.knowledge.your[postType]);
          document.getElementById("post_field").focus();
          return;
        }
      }
    }

    //gather data
    const formdata = new FormData();
    formdata.append("bid", bid);
    formdata.append("knowledge_type", postType);
    formdata.append("title", headline.trim());
    if (doc) {
      formdata.append("docType", docType.current);
      docFile.forEach((file) => {
        formdata.append(docType.current, file);
      });
    } else {
      formdata.append("post", post.trim());
      let n = 0;
      if (imageFiles.length > 0) {
        imageFiles.forEach((file) => {
          formdata.append("image" + n, file);
          n++;
        });
      }
    }

    //call API
    const jwt = encryption.decrypt(localStorage.tag);
    const configurationObject = {
      url: `${
        config.server.document
      }/knowledge/create/hub/${localStorage.getItem("client_id")}`,
      method: "POST",
      data: formdata,
      headers: { "Content-Type": "multipart/form-data", "x-access-token": jwt },
      withCredentials: true,
      credentials: "include",
    };

    setUploadModal(true);
    setProcessing(true);

    axios(configurationObject)
      .then(async (response) => {
        if (response.status === 202) {
          let r = await refreshToken();
          if (r) {
            //token refreshed try again
            submitKnowledge();
          } else {
            //refresh token expired
            signout();
          }
        } else if (response.status === 200) {
          //success
          setProcessing(false);
          if (setKnowledge) setKnowledge(response.data);
        } else {
          setProcessing(false);
          setUploadModal(false);
          if (response.data.dlp) {
            setDlp(response.data.dlp);
          } else {
            setUnknownErr(true);
          }
        }
      })
      .catch((error) => {
        //unknown error
        signout();
      });
  };

  const clearErrors = () => {
    setErrHeadline(false);
    setErrPost(null);
    setErrImages(null);
    setErrDocument(null);
    setDlp("");
    setUnknownErr(false);
  };

  const resetFiles = () => {
    _imageFiles.current = [];
    setImageFiles([]);
    setImageString("");
    _docFile.current = [];
    setDocFile([]);
    setDocString("");
    _filesSize.current = 0;
    setFilesSize(0);
  };

  ////////////// FILES //////////////////

  const addImage = async (file, hexcode, n, N) => {
    if (_filesSize.current + file.size > config.max_attachment) {
      const size = formatBytes(config.max_attachment);
      if (_filesSize.current > 0) {
        setErrImages(language.labels.error.exceeded.replace(/{size}/g, size));
      } else {
        setErrImages(
          language.labels.error.exceeded_one.replace(/{size}/g, size)
        );
      }
      return n / N;
    }

    if (!config.file.imageCodes.includes(hexcode)) {
      setErrImages(language.labels.error.image);
      return n / N;
    }

    _filesSize.current = _filesSize.current + file.size;
    _imageFiles.current.push(file);
    return n / N;
  };

  const addDocument = async (file, hexcode) => {
    if (file.size > config.max_attachment) {
      const size = formatBytes(config.max_attachment);
      setErrDocument(
        language.labels.error.exceeded_document.replace(/{size}/g, size)
      );
      return "error";
    }

    if (!config.file.documentCodes.includes(hexcode)) {
      setErrDocument(language.labels.error.document);
      return "error";
    }

    if (
      hexcode !== "25-50-44" &&
      !file.type.includes(".doc") &&
      !file.type.includes(".ppt") &&
      !file.type.includes(".xls")
    ) {
      setErrDocument(language.labels.error.document);
      return "error";
    }

    _filesSize.current = file.size;
    _docFile.current = [file];
    if (hexcode == "25-50-44") {
      docType.current = "pdf";
      docIcon.current = Pdf;
    } else {
      docType.current = "office";
      docIcon.current = Office;
    }
    return "ok";
  };

  const readImageFiles = async (event) => {
    clearErrors();
    if (event.target) {
      let n = 0;
      for (let file of event.target.files) {
        await new Promise((resolve, reject) => {
          n++;
          const N = event.target.files.length;

          const fileReader = new FileReader();
          fileReader.onloadend = async function (event) {
            const arr = new Uint8Array(event.target.result).subarray(0, 3);
            let hexcode = "";
            for (let index = 0; index < 3; index++) {
              if (index > 0) hexcode += "-";
              hexcode += arr[index].toString(16);
            }
            const res = await addImage(file, hexcode, n, N);
            if (res === 1) {
              setFilesSize(_filesSize.current);
              setImageFiles(_imageFiles.current);
              setImageString(JSON.stringify(_imageFiles.current));
              event.target.value = null;
            }
          };
          fileReader.readAsArrayBuffer(file);
          resolve();
        });
      }
    }
  };
  const readDocFile = (event) => {
    clearErrors();
    if (event.target && event.target.files.length > 0) {
      const file = event.target.files.item(0);
      const fileReader = new FileReader();
      fileReader.onloadend = async function (event) {
        const arr = new Uint8Array(event.target.result).subarray(0, 3);
        let hexcode = "";
        for (let index = 0; index < 3; index++) {
          if (index > 0) hexcode += "-";
          hexcode += arr[index].toString(16);
        }
        const res = await addDocument(file, hexcode);
        if (res === "ok") {
          setFilesSize(_filesSize.current);
          setDocFile(_docFile.current);
          setDocString(JSON.stringify(_docFile.current));
          event.target.value = null;
        } else {
          resetFiles();
        }
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  const removeImageFile = async (index) => {
    clearErrors();
    let cpy = imageFiles.filter((file, fileIndex) => {
      return fileIndex !== index;
    });
    _imageFiles.current = cpy;
    setImageFiles(cpy);
    setImageString(JSON.stringify(cpy));
    let fs = 0;
    for (var i = 0; i < cpy.length; i++) {
      const size = cpy[i].size;
      fs = fs + size;
    }
    _filesSize.current = Math.max(fs, 0);
    setFilesSize(Math.max(fs, 0));
  };

  const setPostMethod = (value, ai = false) => {
    //reset post data
    resetFiles();
    clearErrors();
    setDoc(value);
    setAiAssist(ai);
  };

  const toggleQuestion = () => {
    if (postType === "question") {
      setPostType("general");
    } else {
      setPostType("question");
      setPostMethod(false);
    }
  };

  ////////////// RENDER GUI //////////////////

  const imageAttachments = imageFiles.map((file, index) => (
    <div
      key={index}
      className="file-container"
      onClick={() => removeImageFile(index)}
      role="img"
      aria-label={file.name}
    >
      <div
        role="button"
        className="close glyphs"
        custom={index}
        tabIndex={0}
        onKeyDown={(e) =>
          (e.key === "Enter" || e.key === " ") && removeImageFile(index)
        }
        aria-label={language.labels.image.remove}
      >
        x
      </div>
      <img
        className="message-uploaded-image"
        src={URL.createObjectURL(file)}
        aria-hidden="true"
      />
    </div>
  ));

  return (
    <div style={{ margin: "1em 0" }}>
      <h2
        className="heading2"
        style={{ fontSize: "1.1em", fontWeight: 500, margin: "0 0.25em" }}
      >
        {language.labels.knowledge.add}
      </h2>

      {/* Knowledge Type */}
      <div
        style={{
          display: "flex",
          gap: "0.5em",
          alignItems: "center",
          margin: "0.25em",
        }}
      >
        <input
          id="type_question"
          type="checkbox"
          checked={postType === "question" ? true : false}
          onChange={() => {
            toggleQuestion();
          }}
        />
        <label className="hover" htmlFor="type_question">
          {language.labels.knowledge.create.question}
        </label>
      </div>

      {/* Creation Method */}
      {postType === "general" && (
        <>
          <div style={{ margin: "1.5em 0.25em 0 0.25em", fontWeight: 500 }}>
            {language.labels.knowledge.create.method}
          </div>

          <div
            style={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              margin: "0.25em",
            }}
          >
            <input
              id="check_post"
              type="checkbox"
              checked={!doc && !aiAssist}
              value={false}
              onChange={() => {
                setPostMethod(false);
              }}
            />
            <label className="hover" htmlFor="check_post">
              {language.labels.knowledge.create.post}
            </label>
          </div>

          <div
            style={{
              display: "flex",
              gap: "0.5em",
              alignItems: "center",
              margin: "0.25em",
            }}
          >
            <input
              id="check_document"
              type="checkbox"
              checked={doc}
              value={true}
              onChange={() => {
                setPostMethod(true);
              }}
            />
            <label className="hover" htmlFor="check_document">
              {language.labels.app.document_upload}
            </label>
          </div>
        </>
      )}

      {/* AI Assistant */}
      {postType && !doc && postType !== "question" && (
        <>
          <div style={{ margin: "1.5em 0.25em 0 0.25em", fontWeight: 500 }}>
            {language.labels.tips.ai}
          </div>
          <div style={{ maxWidth: "44rem" }}>
            {language.labels.knowledge.ai}
          </div>
          <button
            className="button"
            style={{ margin: ".75em 0", fontSize: "0.9em" }}
            onClick={preAssist}
          >
            {language.labels.ai.use}
          </button>
        </>
      )}

      {postType && (
        <>
          <label
            htmlFor="title_field"
            style={{
              display: "block",
              margin: "1em 0.25em 0 0.25em",
              fontWeight: 500,
            }}
          >
            {language.labels.knowledge.headline[postType]}
          </label>
          <input
            id="title_field"
            value={headline}
            onChange={(e) => {
              setHeadline(e.target.value);
            }}
            onKeyUpCapture={(e) => e.key === "Enter" && submitKnowledge()}
            placeholder={language.labels.knowledge.headline_enter}
            aria-placeholder={language.labels.knowledge.headline_enter}
            style={{ width: "100%", maxWidth: "44rem", display: "block" }}
            maxLength={config.string.title}
          />
          {errHeadline && (
            <div className="errtext" role="alert">
              {language.labels.knowledge.error.headline}
            </div>
          )}
        </>
      )}

      {postType && !doc && (
        <>
          {/* Post */}
          <label
            htmlFor="post_field"
            style={{
              display: "block",
              margin: "1.5em 0.25em 0 0.25em",
              fontWeight: 500,
            }}
          >
            {language.labels.knowledge.post[postType]}
          </label>
          <TextareaAutosize
            id="post_field"
            value={post}
            onChange={(e) => {
              setPost(e.target.value);
            }}
            placeholder={language.labels.knowledge.post_enter}
            aria-placeholder={language.labels.knowledge.post_enter}
            minRows="4"
            style={{ width: "100%", maxWidth: "44rem" }}
            maxLength={config.string.article}
          />
          {errPost && (
            <div
              className="errtext"
              role="alert"
              style={{ marginBottom: "0.5em" }}
            >
              {errPost}
            </div>
          )}

          {/* Images */}
          <div>
            <label
              role="button"
              style={{ padding: "0 0.25em" }}
              tabIndex="0"
              htmlFor="image-file-input"
              className="link"
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") &&
                document.getElementById("image-file-input").click()
              }
            >
              {language.labels.app.images_button}
            </label>
            <input
              id="image-file-input"
              key={imageString}
              style={{ display: "none" }}
              onChange={(e) => readImageFiles(e)}
              type="file"
              accept={config.file.imageTypes.toString()}
              multiple
            />
          </div>

          {imageFiles.length > 0 && (
            <div
              className="message-file-view"
              aria-label={language.labels.aria.selected_images}
            >
              {imageAttachments}
              <div style={{ margin: "0.25em 0.25em -0.5em 0.25em" }}>
                {formatBytes(filesSize, 1)}
              </div>
            </div>
          )}
          {errImages && (
            <div
              style={{ fontWeight: 600, marginTop: "0.5rem" }}
              className="errtext"
              role="alert"
            >
              {errImages}
            </div>
          )}
        </>
      )}

      {postType && doc && (
        <>
          {/* Document */}
          <div style={{ margin: "1.5em 0.25em 0 0.25em", fontWeight: 500 }}>
            {language.labels.app.document}
          </div>

          {docFile.length === 1 && (
            <div style={{ display: "flex", gap: "0.5em", marginTop: "0.25em" }}>
              <img src={docIcon.current} />
              <div>
                <div style={{ margin: "0.5em 0" }}>{docFile[0]?.name}</div>
                <div>{formatBytes(filesSize, 1)}</div>
              </div>
            </div>
          )}

          {errDocument && (
            <div
              style={{ fontWeight: 600, marginTop: "0.5rem" }}
              className="errtext"
              role="alert"
            >
              {errDocument}
            </div>
          )}

          <div style={{ marginTop: "0.5em" }}>
            <label
              role="button"
              style={{ padding: "0 0.25em" }}
              tabIndex="0"
              htmlFor="doc-file-input"
              className="link"
              onKeyDown={(e) =>
                (e.key === "Enter" || e.key === " ") &&
                document.getElementById("doc-file-input").click()
              }
            >
              {docFile.length === 1
                ? language.labels.app.document_change
                : language.labels.app.document_select}
            </label>
            <input
              id="doc-file-input"
              key={docString}
              style={{ display: "none" }}
              onChange={(e) => readDocFile(e)}
              type="file"
              accept={config.file.documentTypes.toString()}
            />
          </div>
        </>
      )}

      {dlp && (
        <div
          style={{
            fontWeight: 600,
            marginTop: "1.5rem",
            marginBottom: "-1rem",
          }}
          className="errtext"
          role="alert"
        >
          {language.labels.error.dlp_content[dlp]}
        </div>
      )}

      {unknownErr && (
        <div
          style={{
            fontWeight: 600,
            marginTop: "1.5rem",
            marginBottom: "-1rem",
          }}
          className="errtext"
          role="alert"
        >
          {language.labels.error.unknown}
        </div>
      )}

      {/* Submit/Back */}
      <div style={{ display: "flex", gap: "0.25em", margin: "2.5em 0" }}>
        <button className="button-secondary" onClick={() => onCancel()}>
          {cancelLabel}
        </button>
        {postType &&
          headline.trim() !== "" &&
          (post.trim() !== "" ||
            docFile.length === 1 ||
            postType === "question") && (
            <button className="button" onClick={() => submitKnowledge()}>
              {language.labels.app.add}
            </button>
          )}
      </div>

      {/* Uploading FilesModal */}
      <Modal className="modal-dynamic" show={uploadModal}>
        {processing ? (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">
              {language.labels.content.process[postType]}
            </h2>
            <div
              className="dotdotdot"
              style={{ padding: "0.5rem", fontSize: "1.1em" }}
            >
              {language.labels.content.processing[postType]}
            </div>
          </div>
        ) : (
          <div style={{ margin: "1em 0" }}>
            <h2 className="heading">{language.labels.app.done}</h2>
            <div style={{ padding: "0.5rem", fontSize: "1.1em" }}>
              {language.labels.content.done[postType]}
            </div>
            <button
              className="button"
              style={{ margin: "1em 0.25em" }}
              onClick={() => onSuccess()}
            >
              {language.labels.app.ok}
            </button>
          </div>
        )}
      </Modal>

      {/* AI Waiting Modal */}
      <Modal className="modal-dynamic" show={aiModal}>
        <div style={{ margin: "1em 0" }}>
          <h2 className="heading">{language.labels.loader.wait}</h2>
          <div style={{ fontSize: "1.1em", padding: "0.5rem" }}>
            {language.labels.ai.moment}
          </div>
          <div
            className="dotdotdot"
            style={{ fontSize: "1.1em", padding: "0.5rem" }}
          >
            {language.labels.ai.knowledge}
          </div>
        </div>

        <div style={{ display: "flex", marginTop: "1em" }}>
          <button className="button-off" onClick={() => abort()}>
            {language.labels.app.cancel}
          </button>
        </div>
      </Modal>

      {/* Confirm AI Modal */}
      <Modal
        className="modal-dynamic"
        title={language.labels.ai.use}
        show={aiRedo}
      >
        <div>{language.labels.knowledge.ai_redo}</div>
        <div style={{ display: "flex", gap: "0.25em", marginTop: "2em" }}>
          <button className="button-off" onClick={() => setAiRedo(false)}>
            {language.labels.app.cancel}
          </button>
          <button className="button" onClick={() => assist()}>
            {language.labels.app.ok}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default KnowledgeCreator;
