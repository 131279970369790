import React from "react";
import { useNavigate } from "react-router-dom";
import { GetApp } from "../widgets";

const Welcome = ({ language, os, main_focus }) => {
  const navigate = useNavigate();

  return (
    <main className="main-page" ref={main_focus} tabIndex={0}>
      <div
        className="page-section"
        style={{ textAlign: "center", maxWidth: "44rem", paddingBottom: "3em" }}
      >
        <h1 className="heading public-center">
          {language.labels.brand.yongo_mobile}
        </h1>

        <div
          className="public-center"
          style={{ margin: "0.5em 0 1em 0", fontSize: "1.2em" }}
        >
          {language.labels.brand.about_app}
        </div>
        <GetApp os={os} myclass={"public-center"} />
      </div>

      <div
        className="page-section"
        style={{
          marginBottom: "1em",
          maxWidth: "44rem",
        }}
      >
        <h1 className="heading public-center">
          {language.labels.brand.yongo_hub}
        </h1>

        <div
          className="public-center"
          style={{ marginTop: "0.5em", fontSize: "1.2em" }}
        >
          {language.labels.brand.about_hub}
        </div>

        <div className="public-center" style={{ margin: "1em 0" }}>
          <button
            id="new_link"
            className="button"
            onClick={() => {
              navigate("/newcommunity");
            }}
          >
            {language.labels.navigation.community_start}
          </button>
        </div>
      </div>
    </main>
  );
};

export default Welcome;
