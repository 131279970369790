import { React, useState } from "react";
import axios from "axios";

import config from "../../../config";

const SelectPage = ({ language, uid, cid, targets, setTarget, setPage }) => {
  ////////////////// INITIALIZE //////////////////
  const [errMsg, setErrMsg] = useState("");

  ////////////////// SEND CODE //////////////////
  const sendCode = async (obj) => {
    try {
      let data = { cid, uid, resend: false, language: language.lang };
      if (obj.phone) data.phone = obj.phone;
      if (obj.email) data.email = obj.email;

      setErrMsg("");
      const configurationObject = {
        url: `${config.server.api}/v2/auth/sendcode/hub`,
        method: "POST",
        data,
      };
      document.getElementById("loader").style.display = "inline";
      const response = await axios(configurationObject);
      document.getElementById("loader").style.display = "none";
      if (response.status === 200) {
        setTarget(obj);
        setPage("code");
      } else {
        console.log(response.data);
        switch (response.data.message) {
          case "SMS":
            setErrMsg(language.labels.error.signin_sms);
            break;
          case "MAIL":
            setErrMsg(language.labels.error.signin_mail);
            break;
          default:
            setErrMsg(language.labels.error.signin_expired);
        }
      }
    } catch (error) {
      document.getElementById("loader").style.display = "none";
      setErrMsg(language.labels.error.unknown);
    }
  };

  ////////////////// RENDER GUI //////////////////
  const phone = targets
    .filter((data) => data.phone)
    .map((obj, index) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5em 0",
            }}
          >
            <div style={{ flex: 1, fontWeight: 300 }}>{obj.mask}</div>
            <button
              className="button"
              style={{ fontSize: "0.8em" }}
              onClick={() => {
                sendCode(obj);
              }}
            >
              {language.labels.authentication.send}
            </button>
          </div>
        </>
      );
    });

  const emails = targets
    .filter((data) => data.email)
    .map((obj, index) => {
      return (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0.5em 0",
            }}
          >
            <div style={{ flex: 1, fontWeight: 300 }}>{obj.mask}</div>
            <button
              className="button"
              style={{ fontSize: "0.8em" }}
              onClick={() => {
                sendCode(obj);
              }}
            >
              {language.labels.authentication.send}
            </button>
          </div>
        </>
      );
    });

  return (
    <div
      className="signup"
      id="select"
      aria-label={language.labels.aria.form.password}
      style={{ paddingBottom: "2em" }}
    >
      <div style={{ textAlign: "center", fontSize: "1.2em" }}>
        {language.labels.authentication.verify_identity}
      </div>

      <div style={{ textAlign: "center", marginTop: "1.2em" }}>
        {language.labels.authentication.mfa_choose}
      </div>

      {targets.filter((data) => data.phone).length > 0 && (
        <>
          <h3
            className="heading2"
            style={{ marginTop: "2em", fontWeight: 500 }}
          >
            {language.labels.authentication.phone_number}
          </h3>
          {phone}
        </>
      )}

      {targets.filter((data) => data.email).length > 0 && (
        <>
          <h3
            className="heading2"
            style={{ marginTop: "2em", fontWeight: 500 }}
          >
            {language.labels.authentication.email}
          </h3>
          {emails}
        </>
      )}

      {errMsg && (
        <div className="errtext" role="alert" style={{ marginTop: "1rem" }}>
          {errMsg}
        </div>
      )}
    </div>
  );
};

export default SelectPage;
